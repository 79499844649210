import { useState } from 'react';
import { Favorite } from '../favorite';
import Close from '$icons/close-bold.svg';
import Dots from '$icons/dots.svg';
import { Icon, useTracking } from '~/shared/components';
import {
    StyledOpenButton,
    StyledOptionsButton,
    StyledOptionsFavorite,
    StyledProductOptions,
} from './styled';
import { useIsDesktop } from '~/shared/hooks/useIsDesktop/useIsDesktop';
import { AnimatePresence } from 'framer-motion';
import { Popover } from './components/Popover';
import { Product } from '../../model';
import { usePage } from '~/templates/pages';
import { countryCodeKey, countryDictionary } from '~/lib/twoLetterIsoCodeCountryList';

export type ProductOptions = {
    product: Product;
    products?: Product[];
    variant?: 'transparent' | 'default';
};

export const ProductOptions = ({ product, products, variant = 'default' }: ProductOptions) => {
    const isDesktop = useIsDesktop();
    const [isOpen, setIsOpen] = useState(false);
    const { trackOpenConfigurator } = useTracking();
    const { market = 'int' } = usePage();

    const handleOptionsButton = () => {
        !isOpen &&
            trackOpenConfigurator({
                country: (market && countryDictionary[market as countryCodeKey]) || market,
                product_name: product.displayName,
                product_id: product.productId,
                product_category: product?.categoryPaths?.[0]?.pathFromRoot?.[0]?.displayName,
                product_category2: product?.categoryPaths?.[0]?.pathFromRoot?.[1]?.displayName,
                product_category3: product?.categoryPaths?.[0]?.pathFromRoot?.[2]?.displayName,
            });
        setIsOpen((currentValue) => !currentValue);
    };

    let closeOptionRef: NodeJS.Timeout | undefined;

    const handlersMouseEnter = () => {
        clearTimeout(closeOptionRef);
    };

    const handleCloseOptionsButton = () => {
        clearTimeout(closeOptionRef);

        closeOptionRef = setTimeout(() => {
            setIsOpen(false);
        }, 800);
    };

    if (!isDesktop) {
        return (
            <StyledOptionsFavorite variant={variant} isOpen={isOpen}>
                <Favorite product={product} />
            </StyledOptionsFavorite>
        );
    }

    return (
        <StyledProductOptions
            onMouseLeave={handleCloseOptionsButton}
            onMouseEnter={handlersMouseEnter}
        >
            <StyledOptionsFavorite variant={variant} isOpen={isOpen}>
                <Favorite product={product} />
            </StyledOptionsFavorite>

            <div>
                <StyledOptionsButton
                    onClick={handleOptionsButton}
                    isOpen={isOpen}
                    variant={variant}
                >
                    {isOpen ? (
                        <Icon size={'md'}>
                            <Close title={'Close'} />
                        </Icon>
                    ) : (
                        <StyledOpenButton>
                            <Icon size="md">
                                <Dots title={'open'} />
                            </Icon>
                        </StyledOpenButton>
                    )}
                </StyledOptionsButton>

                <AnimatePresence>
                    {isOpen ? (
                        <Popover product={product} variant={variant} products={products} />
                    ) : null}
                </AnimatePresence>
            </div>
        </StyledProductOptions>
    );
};
